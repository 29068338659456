import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";
import Column from "antd/lib/table/Column";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
     
            <FooterContainer>
              &copy; {new Date().getFullYear()} Coden IT Consulting SRL
              <p className="footer-subtitle" style={{ fontSize: '14px' }}>
									<a href="mailto:office@coden.ro">
										office@<span style={{ display: 'none' }}>null</span>coden.ro
									</a>{' '}
									|{' '}
									<a href="tel:+40775238558">
										(+40)<span style={{ display: 'none' }}>null</span> 775 238
										558
									</a>{' '}
									|{' '}
									<a
										href={process.env.PUBLIC_URL + '/privacy-policy.pdf'}
										target="_blank"
										rel="noopener noreferrer"
									>
										Privacy Policy
									</a>{' '}
									|{' '}
									<a
										href={process.env.PUBLIC_URL + '/terms-and-conditions.pdf'}
										target="_blank"
										rel="noopener noreferrer"
									>
										Terms and Conditions
									</a>
								</p>
								<span className="footer-subtitle">
									Cyber Insurance Policy No. ESM0239780595
								</span>
            </FooterContainer>
    </>
  );
};

export default withTranslation()(Footer);
