import { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";
import './not-found.css'

const Router = () => {
  return (
    <Suspense fallback={null}>
      <Styles />
      <Header />
      <Switch>
        {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
      <Footer />
    </Suspense>
  );
};


const NotFound = () => {
  return (
    <div className="not-found1-container">
      {<p>404</p>
      /*<div className="not-found1-container1">
        <h3 className="not-found1-text1">404</h3>
      </div>
      <div className="not-found1-container2"> */}
        <p className="not-found1-text2">
          Page not found
        </p>
      {/* </div> */}
    </div>
  )
}

export default Router;
